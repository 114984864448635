import React from "react";
import { Row, Container, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
function Joinpresalenow({ setSignupShow }) {
  return (
    <>
      <Container className="join-presale-now text-center" fluid>
        <Row>
          <Container>
            <Row onClick={() => {
                    setSignupShow(true);
                  }}>
              <Col xl="12">
                <div className="join-presale">
                  <h1 className="chakra">
                    Join Presale <span className="color">Now!</span>
                  </h1>
                  <h4 className="font-weight-normal Titillium">
                    Participate in the project and witness your impact <br></br>{" "}
                    soar to unprecedented levels.
                  </h4>
                  <div className="spacer-20" />
                  <ul className="btn-list justify-center mv-full">
                    <li>
                      <Link
                        onClick={() => {
                          setSignupShow(true);
                        }}
                        className="reg-btn br-radius"
                        to={void 0}
                      >
                        JOIN Presale
                      </Link>
                    </li>
                    <li>
                      <Link className="reg-btn br-radius trans" to="/HowToBuy">
                        Learn More
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </Row>
      </Container>
    </>
  );
}
export default Joinpresalenow;
