import React, { useContext, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Row, Container, Col, Tab, Tabs } from "react-bootstrap";
import Frequentlyquestion from "../Components/Frequentlyquestion";
import latestbg from "../Assets/img/Bg/bg-3.png";
import dawnbg from "../Assets/img/Bg/bg-dawn.png";
import arrowleft from "../Assets/img/Icons/icon-arrow-1.png";
import arrowright from "../Assets/img/Icons/icon-arrow-2.png";
import Partner1 from "../Assets/img/Partners/velas.png";
import Partner2 from "../Assets/img/Partners/duckdao.png";
import Partner3 from "../Assets/img/Partners/bondly.png";
import Partner4 from "../Assets/img/Partners/mxc.png";
import Partner5 from "../Assets/img/Partners/gbv.png";
import Partner6 from "../Assets/img/Partners/gate.png";
import Partner7 from "../Assets/img/Partners/au21.png";
import aboutbg from "../Assets/img/Bg/about-bg.png";
import Post from "../Assets/img/Posts/about.png";
import Roadmaplist from "../Components/Roadmaplist";
import UserContest from "../ContextAPI/UserContest";
import { Signup } from "../Api";
import { videoUrl } from "../Constants";
import { useAccount, useDisconnect } from "wagmi";
import LatestNewsSlider from "../Components/LatestNewsSlider";
function About({
  setSignupShow,
  RewardSectionRef,
  TokenSectionRef,
  RoadSectionRef,
  FAQeSectionRef,
  SignupShow,
  featuredSectionRef,
  setShowNavBar,
  setShowFooter,
}) {
  const { CurrentStage, TotalSaleInStage } = useContext(UserContest);
  useEffect(() => {
    setShowNavBar(true);
    setShowFooter(true);
  }, []);
  const navigate = useNavigate();
  const { address, isConnecting, isDisconnected } = useAccount();
  // RewardSectionRef={RewardSectionRef}
  // TokenSectionRef={TokenSectionRef}
  // RoadSectionRef={RoadSectionRef}
  // WhiteSectionRef={WhiteSectionRef}

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.pathname]);

  return (
    <>
      <Container fluid className="header text-center">
        <div
          className="bg-layer"
          style={{ backgroundImage: `url(${aboutbg})` }}
        />
        <Row>
          <Container>
            <Row>
              <Col xl="12" lg="12">
                <div className="text-pnl">
                  <h5>About new dawn finance</h5>
                  <h2>
                    Step Into the Future of
                    <br /> Crypto Trading
                  </h2>
                  <img src={Post} alt="Post" />
                  {/* <Col xl="12">
                    <div className="video-pnl" style={{ height: "400px" }}>
                      <iframe
                        src={videoUrl}
                        height="400"
                        frameBorder="0" // Add this to remove iframe border if needed
                        allowFullScreen // Add this to enable fullscreen mode
                        title="video"
                      ></iframe>
                    </div>
                    <div className="spacer-50" />
                  </Col> */}
                  <p className="f-20">
                    New Dawn Finance is a platform that makes it easy to access
                    and participate in the exciting world of decentralized
                    finance. Our cross-chain platform enables a wide range of
                    people from all over the world to easily select or create
                    indexes in a simple to use platform.
                  </p>
                  <div className="spacer-20" />
                  <ul className="btn-list">
                    <li>
                      <Link className="reg-btn trans br-radius" href="#">
                        read White paper
                      </Link>
                    </li>
                    <li>
                      <Link className="reg-btn trans br-radius" to="/AboutVideo">
                        check one pager
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </Row>
      </Container>
      {/* Featured Panel Starts Here */}
      <section className="container-fluid latest-news-panel text-center">
        <div
          className="bg-layer"
          style={{ backgroundImage: `url(${latestbg})` }}
        />
        <Row>
          <Container>
            <Row>
              <Col
                xxl={{ span: 6, offset: 3 }}
                xl={{ span: 8, offset: 2 }}
                lg={{ span: 10, offset: 1 }}
              >
                <h2 className="z-ind">
                  <img src={arrowleft} alt="Arrow Left" /> Latest News{" "}
                  <img src={arrowright} alt="Arrow Right" />
                </h2>
                <h4 className="lighter-yellow font-weight-normal Titillium z-ind">
                  News, Blog and Educational in finance world
                </h4>
                <div className="spacer-20" />
              </Col>
            </Row>
            <Row>
              <Col xl="12" lg="12" md="12">
                <LatestNewsSlider />
              </Col>
            </Row>
          </Container>
        </Row>
      </section>
      {/* Featured Panel Ends Here */}

      {/* Road Map Panel Starts Here */}
      <section className="pb-0 container-fluid">
        <Row>
          <Container>
            <Row>
              <Col xl="12" lg="12" className="text-center">
                <h2>
                  {" "}
                  <img src={arrowleft} alt="Arrow Left" /> NDF Roadmap{" "}
                  <img src={arrowright} alt="Arrow Right" />
                </h2>
                <h4 className="font-weight-normal lighter-yellow Titillium">
                  Short text about roadmap short text about roadmap short
                  <br></br> text about roadmap short text about roadmap
                </h4>
              </Col>
              <Col xl="12" lg="12" md="12">
                {/* Road Map */}
                <Roadmaplist />
                {/* Road Map */}
              </Col>
            </Row>
          </Container>
        </Row>
      </section>
      {/* Road Map Panel Ends Here */}

      {/* Partnership Starts Here */}
      <section className="container-fluid partnership-panel text-center">
        <div
          className="bg-layer"
          style={{ backgroundImage: `url(${dawnbg})` }}
        />
        <Row>
          <Container>
            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <h2 className="z-ind">
                  <img src={arrowleft} alt="Arrow Left" /> Our Partners{" "}
                  <img src={arrowright} alt="Arrow Right" />
                </h2>
              </Col>
              <Col xxl={{ span: 8, offset: 2 }} xl={{ span: 8, offset: 2 }}>
                <div className="spacer-30" />
                <ul className="partner-list">
                  <li>
                    <img src={Partner1} alt="Partner" />
                  </li>
                  <li>
                    <img src={Partner2} alt="Partner" />
                  </li>
                  <li>
                    <img src={Partner3} alt="Partner" />
                  </li>
                  <li>
                    <img src={Partner4} alt="Partner" />
                  </li>
                  <li>
                    <img src={Partner5} alt="Partner" />
                  </li>
                  <li>
                    <img src={Partner6} alt="Partner" />
                  </li>
                  <li>
                    <img src={Partner7} alt="Partner" />
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </Row>
      </section>
      {/* Partnership Ends Here */}
      {/* Frequently Question Panel Starts Here */}
      {/* <section
        ref={FAQeSectionRef}
        className="container-fluid faq-container text-center"
      >
        <Row>
          <Container>
            <Row>
              <Col xl="12" lg="12" md="12">
                <h2>
                  <img src={arrowleft} alt="Arrow Left" /> Frequently Asked
                  Questions <img src={arrowright} alt="Arrow Right" />
                </h2>
                <div className="spacer-40" />
              </Col>
              <Col xxl={{ span: 8, offset: 2 }} xl={{ span: 8, offset: 2 }}>
                <Tabs
                  defaultActiveKey="NDFProtocol"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                >
                  <Tab eventKey="NDFProtocol" title="NDF Protocol">
                    <Frequentlyquestion />
                  </Tab>
                  <Tab eventKey="Token" title="Token">
                    <Frequentlyquestion />
                  </Tab>
                  <Tab eventKey="governance" title="governance">
                    <Frequentlyquestion />
                  </Tab>
                </Tabs>
              </Col>
            </Row>
          </Container>
        </Row>
      </section> */}
      {/* Frequently Question Panel Ends Here */}
    </>
  );
}
export default About;
