import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Container, Col } from "react-bootstrap";
import arrowleft from "../Assets/img/Icons/icon-arrow-1.png";
import arrowright from "../Assets/img/Icons/icon-arrow-2.png";
function TermsConditions() {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.pathname]);
  return (
    <>
      {/* Terms And Conditions Starts Here */}
      <section className="terms-policy container-fluid">
        <Row>
          <Container>
            <Row>
              <Col xl="12" lg="12" md="12" className="text-center">
                <h1>
                  <img src={arrowleft} alt="Arrow Left" /> {" "} Terms and Conditions{" "}
                  <img src={arrowright} alt="Arrow Right" />
                </h1>
              </Col>
              <Col xl="12" lg="12" md="12">
                <h3>Terms Of Use</h3>
                <p>
                  These Terms govern your access to, usage of all content,
                  Product and Services available at website (the “Service”)
                  operated by (“us”, “we”, or “our”). Your access to our
                  services is subject to your acceptance, without modification,
                  of all of the terms and conditions contained herein and all
                  other operating rules and policies published and that may be
                  published from time to time by us. Please read the Agreement
                  carefully before accessing or using our Services. By accessing
                  or using any part of our Services, you agree to be bound by
                  these Terms. If you do not agree to any part of the terms of
                  the Agreement, then you may not access or use our Services.
                </p>
                <h3>Copyright</h3>
                <p>
                  We at New Dawn Finance, reserve all copyrights on text or
                  images on the platform. The text or images in the platform may
                  not be copied or distributed without prior permission of New
                  Dawn Finance. If there is any approved use of content, the
                  following conditions should be followed: The source of copied
                  material should be mentioned as New Dawn Finance, this
                  statement should appear on all forms of distribution.
                </p>
                <h3>E-mail</h3>
                <p>
                  You may choose to communicate with us via e-mail. However, in
                  case you do so, you should note that the security of internet
                  e-mail is unreliable. By sending confidential or sensitive
                  e-mail messages which are unencrypted you accept the risks of
                  such uncertainty and possible breach of confidentiality or
                  privacy over the internet.
                </p>
                <h3>No Warranty</h3>
                <p>
                  The information contained is provided by New Dawn Finance as a
                  service/promotion to its users, subscribers, customers and
                  possible others. It does not contain (legal) advice. Although
                  we try to provide quality information, we do not guarantee of
                  results obtained from the use of this information, and without
                  warranty of any kind, express or implied, including, but not
                  limited to warranties of performance for a particular purpose.
                </p>
                <h3>No Liability</h3>
                <p>
                  In no way is New Dawn Finance is liable to user or any other
                  party for any damages, costs of any character including but
                  not limited to direct or indirect, consequential, incidental
                  or other costs or damages, via the use of the information
                  contained in the newsletters.
                </p>
                <h3>Changes</h3>
                <p>
                  We may make changes to this information at any time without
                  prior notice. We make no commitment to update the information
                  contained in this newsletter.
                </p>
                <h3>Intellectual Property</h3>
                <p>
                  The Agreement does not transfer from Us to you any of Ours or
                  third-party intellectual property, and all right, title, and
                  interest in and to such property will remain (as between the
                  parties) solely with and its licensors.
                </p>
                <h3>Third Party Services</h3>
                <p>
                  In using the Services, you may use third-party services,
                  products, software, embeds, or applications developed by a
                  third party (“Third Party Services”). If you use any
                  Third-Party Services, you understand that: Any use of a
                  Third-Party Service is at your own risk, and we shall not be
                  responsible or liable to anyone for Third Party websites or
                  Services. You acknowledge and agree that We shall not be
                  responsible or liable for any damage or loss caused or alleged
                  to be caused by or in connection with the use of any such
                  content, goods or services available on or through any such
                  web sites or services.
                </p>
                <h3>Accounts</h3>
                <p>
                  Where use of any part of our Services requires an account, you
                  agree to provide us with complete and accurate information
                  when you register for an account. You will be solely
                  responsible and liable for any activity that occurs under your
                  account. You are responsible for keeping your account
                  information up-to-date and for keeping your password secure.
                  You are responsible for maintaining the security of your
                  account that you use to access the Service. You shall not
                  share or misuse your access credentials. You must notify us
                  immediately of any unauthorized uses of your account or upon
                  becoming aware of any other breach of security.
                </p>
                <h3>Termination</h3>
                <p>
                  We may terminate or suspend your access to all or any part of
                  our Services at any time, with or without cause, with or
                  without notice, effective immediately. If you wish to
                  terminate the Agreement or your account, you may simply
                  discontinue using our Services. All provisions of the
                  Agreement which by their nature should survive termination
                  shall survive termination, including, without limitation,
                  ownership provisions, warranty disclaimers, indemnity, and
                  limitations of liability.
                </p>
                <h3>Disclaimer</h3>
                <p>
                  Our Services are provided “AS IS.” and “AS AVAILABLE” basis.
                  and its suppliers and licensors hereby disclaim all warranties
                  of any kind, express or implied, including, without
                  limitation, the warranties of merchantability, fitness for a
                  particular purpose and non-infringement.
                </p>
                <p>
                  Neither, nor its suppliers and licensors, makes any warranty
                  that our Services will be error free or that access thereto
                  will be continuous or uninterrupted. You understand that you
                  download from, or otherwise obtain content or services
                  through, our Services at your own discretion and risk.
                </p>
                <h3>Changes</h3>
                <p>
                  New Dawn Finance reserves the right, at our sole discretion,
                  to modify or replace these Terms at any time. If we make
                  changes that are material, we will let you know by posting on
                  our website, or by sending you an email or other communication
                  before the changes take effect. The notice will designate a
                  reasonable period after which the new terms will take effect.
                  If you disagree with our changes, then you should stop using
                  our Services within the designated notice period, or once the
                  changes become effective. Your continued use of our Services
                  will be subject to the new terms.
                </p>
                <div className="spacer-40" />
              </Col>
              {/* <Col xl="12" lg="12" md="12" className="text-center">
                <Link className="reg-btn br-radius trans" to="/">
                  Back To Homepage
                </Link>
              </Col> */}
            </Row>
          </Container>
        </Row>
      </section>
      {/* Terms And Conditions Ends Here */}
    </>
  );
}
export default TermsConditions;
