// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.scss';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import { BrowserRouter } from 'react-router-dom';
// import UserState from './ContextAPI/UserState';


// ReactDOM.render(
//   <BrowserRouter>
//     <UserState>
//     <App />
//     </UserState>
//   </BrowserRouter>,
//   document.getElementById('root')
// );
// reportWebVitals();
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import UserState from './ContextAPI/UserState';
import { ThirdwebProvider, ChainId } from '@thirdweb-dev/react';

ReactDOM.render(
  <BrowserRouter>
    <UserState>
      {/* Wrap your app in ThirdwebProvider */}
      <ThirdwebProvider activeChain={ChainId.Mainnet}>
        <App />
      </ThirdwebProvider>
    </UserState>
  </BrowserRouter>,
  document.getElementById('root')
);

reportWebVitals();
