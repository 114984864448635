import React from "react";
import Accordion from "react-bootstrap/Accordion";
function Frequentlyquestion() {
  return (
    <>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <span></span> Why Buy $NDF in Presale?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              In the presale stage, investors can acquire NDF tokens for a major
              discount. Our starting price is $0.01 and will continue to
              increase in short iterations.
            </p>
            <p>
              Upon hitting the listing price of $0.20, NDF will be listed on
              Uniswap and other major exchanges. Early participants can make
              upto 20x gains by joining during the initial phases. 
            </p>
            <p>
              The presale is designed to provide liquidity to New Dawn Finance
              for trading initiation. This allows us to skip the conventional
              fundraising route and democratize returns directly to our
              investors.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <span></span>How Can I Track NDF Trades?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              Our trading bot is deployed on the blockchain. This means that
              you’ll be able to track data through blockchain ledgers.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            <span></span>
            Do I Need to Give Manual Input For Trades?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              Nope! Investors just need to hold NDF tokens while our trading
              engine executes trades on the blockchain. By holding the tokens,
              you’ll receive automated profits through the $NDF token.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>
            <span></span>How Is The Trading Yield Calculated?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              The monthly returns are calculated based on the trading output of
              the New Dawn Finance engine. Based on current performance, the
              yield is updated in real-time. In beta test deployments, our
              engine has averaged over 12% in monthly returns (144% APY).
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>
            <span></span>How Is The Trading Yield Calculated?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              New Dawn Finance is the first decentralized hedge fund that relies
              entirely on AI. Our process does not require any manual
              intervention from trade selection to execution to profit sharing
              directly in your wallet.
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}
export default Frequentlyquestion;
