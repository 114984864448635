import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
export const PresaleNowButton = ({ setSignupShow = () => { } }) => (
  <>
    <div className="mt-4 mb-1">
      <Link
        onClick={() => {
          setSignupShow(true);
        }}
        className="reg-btn br-radius"
        to={void 0}
      >
        Join Presale NOW
      </Link>
      {/* <Button className="reg-btn br-radius">
                    Join Presale NOW
                  </Button> */}
    </div>
    <Link className="simple-link" to="/HowToBuy">
      How to Join?
    </Link>
  </>
);

export const JoinLearnButton = ({ setSignupShow = () => { } }) => (
  <>
    <ul className="btn-list justify-center mv-full">
      <li>
        <Link
          onClick={() => {
            setSignupShow(true);
          }}
          className="reg-btn br-radius"
          to={void 0}
        >
          JOIN Presale
        </Link>
      </li>
      <li>
        <Link className="reg-btn br-radius trans" to="/HowToBuy">
          Learn More
        </Link>
      </li>
    </ul>
  </>
);

export const PresaleButton = ({ setSignupShow = () => { } }) => (
  <>
    <Link
      className="reg-btn br-radius mb-1"
      onClick={() => {
        setSignupShow(true);
      }}
      to={void 0}
    >
      Join Presale
    </Link>
    <br />
    <Link className="simple-link" to="/HowToBuy">
      How to Join?
    </Link>
  </>
);

export const PresalesButton = ({ setSignupShow = () => { } }) => (
  <>
    <Link
      className="reg-btn br-radius mb-1"
      onClick={() => {
        setSignupShow(true);
      }}
      to={void 0}
    >
      Join Presale
    </Link>
    <br />
    <Link className="simple-link" to="/HowToBuy">
      How to Join?
    </Link>
  </>
);

export const ReadMoreButton = ({ postLink }) => (
  <>
    <Link
      className="reg-btn br-radius mb-1"
      to={`/Blog/${postLink}`}
      style={{ borderRadius: "10px", padding: "10px" }}
    >
      Read More
    </Link>
  </>
);
