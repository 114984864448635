import React, { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import bg from "../Assets/img/Bg/bg-1.png";
import ETH from "../Assets/img/Icons/etherium.png";
import USDT from "../Assets/img/Icons/icon-teather.png";
import Card from "../Assets/img/Icons/card.png";
import logo from "../Assets/img/Logo/icon-logo.png";
import { Form, Row, Container, Col, Image, ProgressBar } from "react-bootstrap";
import endpoint from "../Assets/img/admin-icons/endpoint.png";
import videoSource from "../Assets/img/video/dawn.mp4";
import {
  GetAllAvaliableCurrencies,
  GetAllStageTransactions,
  getBonusTokenCountWithOutStage,
  GetCurrentStage,
  GetCurrentStageTransactions,
  GetSelectedCurrencyPrice,
  getTokenBonusCount,
  GraphTotal,
} from "../Api";
import { CommasRegex, NoStageActive } from "../Constants";
import UserContest from "../ContextAPI/UserContest";
import debounce from "lodash.debounce";
function Header({ setSignupShow, SignupShow }) {
  const { SetTotalSaleInStage, SetCurrentStage, SetOverAllSaleToken, OverAllSaleToken, TotalSaleInStage, CurrentStage } =
    useContext(UserContest);
  const [selectedCurrency, setSelectedCurrency] = useState("USDT");
  const [amount, setAmount] = useState("USDT");
  const [stage, setStage] = useState([]);
  const [percentage, setPercentage] = useState([]);
  const [totalTokens, setTotalTokens] = useState(0);
  const [totalRaised, setTotalRaised] = useState("Loading...");
  const [bonusWithoutStage, setBonusWithOutSale] = useState();
  const [BonusToken, setBonusToken] = useState();
  const [saleGraph, setsaleGraph] = useState();

  useEffect(() => {
    if (CurrentStage?.id)
      getTokenBonusCount(CurrentStage?.id)
        .then((data) => {
          // ('getTokenBonusCount',data.total_tokens_purchased)
          setBonusToken(parseInt(data.total_tokens_purchased));
        })
        .catch(() => { });
    getBonusTokenCountWithOutStage()
      .then((data) => {
        setBonusWithOutSale(parseInt(data.total_tokens_purchased));
      })
      .catch(() => { });

    GraphTotal()
      .then((data) => {
        setsaleGraph(data.data.totalraised);
      })
      .catch(() => { });
  }, [CurrentStage]);


  useEffect(() => {
    const debouncedFetchCurrencies = debounce(async (currency) => {
      try {
        await GetAllAvaliableCurrencies(currency);
        // Handle the API response if needed
      } catch (error) {
        console.error("Error fetching currency:", error);
      }
    }, 300); // Adjust debounce delay as necessary

    if (selectedCurrency) {
      debouncedFetchCurrencies(selectedCurrency);
    }

    return () => {
      debouncedFetchCurrencies.cancel();
    };
  }, [selectedCurrency]);



  useEffect(() => {
    const fetchCurrentStage = async () => {
      try {
        const stage = await GetCurrentStage();
        setStage(stage);
        SetCurrentStage(stage);
        if (!stage?.id) {
          SetCurrentStage(NoStageActive);
        }
        const data = await GetCurrentStageTransactions(stage.id);
        SetTotalSaleInStage(data.data.total_tokens_purchased);
      } catch (error) {
        SetCurrentStage(NoStageActive);
      }
    };
    GraphTotal();

    const fetchOverallSaleToken = async () => {
      try {
        const data = await GetAllStageTransactions();
        SetOverAllSaleToken(data.data.total_tokens_purchased);
      } catch (error) {
        console.error("Error fetching overall sale token:", error);
      }
    };

    fetchCurrentStage();
    fetchOverallSaleToken();
  }, []);



  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await GetAllStageTransactions();
        if (response?.data?.total_tokens_purchased) {
          setTotalTokens(response.data.total_tokens_purchased);
        }
      } catch (error) { }
    };

    fetchTransactions();
  }, []);

  useEffect(() => {
    const payload = {
      amount: 1, // Fixed amount
      currency_from: selectedCurrency, // Use selectedCurrency
      currency_to: 'USDT', // Fixed currency_to
    };

    const fetchCurrencyPrice = async () => {
      try {
        const response = await GetSelectedCurrencyPrice(payload); // Pass the payload to the API function
      } catch (error) {
        console.error("Error fetching currency price:", error); // Log any errors
      }
    };

    fetchCurrencyPrice(); // Call the function to fetch the currency price
  }, []); // Ensure to include selectedCurrency in the dependency array

  useEffect(() => {
    const fetchTotalRaised = async () => {
      try {
        const response = await GraphTotal();
        setTotalRaised(response.data.totalraised); // Set the total raised value
      } catch (error) {
        console.error("Error fetching total raised:", error);
      }
    };

    fetchTotalRaised();
  }, []);


  useEffect(() => {
    setSelectedCurrency("USDT");
  }, []);
  const calculateTokens = (amount, currency) => {
    const priceOfToken = parseFloat(stage?.price_of_token) || 0;
    const amountInNumber = parseFloat(amount) || 0;
    // Calculate tokens based on the currency
    return (amountInNumber / priceOfToken).toFixed(2);
  };


  useEffect(() => {
    if (!CurrentStage?.total_limit) {
      setPercentage(0); // Handle cases where total_limit is undefined or 0
      return;
    }

    const totalLimit = parseInt(CurrentStage.total_limit);
    const totalSale = parseInt(TotalSaleInStage ?? 0);
    const bonusTokens = parseInt(BonusToken ?? 0);

    // Ensure sold tokens do not exceed the total limit
    const sold = Math.min(totalLimit, totalSale + bonusTokens);

    // Calculate remaining tokens
    const remaining = totalLimit - sold;

    // Calculate percentage of tokens sold
    const percentage = ((sold / totalLimit) * 100).toFixed(2);

    setPercentage(parseFloat(percentage)); // Ensure it's a valid number
  }, [TotalSaleInStage, CurrentStage, saleGraph, BonusToken]);

  return (
    <>
      <Container fluid className="landing-header header text-center">
        <div className="bg-layer">
          <div className="video-wrapper">
            <video className="bg-layer__video" autoPlay loop playsInline muted>
              <source src={videoSource} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
        <Row>
          <Container>
            <Row
              onClick={() => {
                setSignupShow(true);
              }}
            >
              <Col xl="7" lg="6" className="d-flex align-items-center">
                <div className="text-pnl text-left">
                  <h5>Accessibility, Transparency, Security</h5>
                  <h1>
                    Ushering a New Dawn With Decentralization - Earn Passive
                    Income
                  </h1>
                  <h4>
                    Join the First Decentralized Hedge Fund That Offers{" "}
                    <span className="text-gradient">
                      Over 8.2%* Monthly Yield{" "}
                    </span>{" "}
                    Managed Entirely By Artificial Intelligence
                  </h4>
                  <ul className="btn-list">
                    <li>
                      <Link className="reg-btn br-radius" href="#">
                        Join Presale
                      </Link>
                    </li>
                    <li>
                      <Link className="reg-btn trans br-radius" href="#">
                        Learn More
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col xl="5" lg="6">
                <div className="buy-now-landing-panel">
                  <div className="buy-now-landing-panel">
                    <h3>Buy Now Before Price Rises</h3>
                    {/* <div className="progress-bar"> */}
                    {/* <div className="bar" style={{ width: "90%" }} /> */}
                    <div className="progress-bar">
                      {/* <ProgressBar  now={70} label={`${70}%`} />
                      <div className="measure-bar">
                            <img src={endpoint} alt="Arrow" />
                          </div> */}
                      <div className="bar">
                        <ProgressBar
                          className="custom-progress-bar"
                          now={percentage} // Pass the percentage directly
                          label={`${percentage}%`} // Optional: Show percentage as a label
                        />

                      </div>

                      {/* <div className="bar" style={{ width: "40%" }}>
                        <div className="measure-bar">
                            <img src={endpoint} alt="Arrow" />
                          </div>
                        </div> */}
                    </div>
                    {/* </div> */}
                    <ul class="my-token-list w-100 trans">
                      <li>
                        <p>Tokens Sold In Total:</p>
                        <div class="border-span" />
                        <p>
                          <span>
                            ${(
                              parseInt(OverAllSaleToken) +
                              parseInt(bonusWithoutStage)
                            )
                              ?.toString()
                              .replace(CommasRegex, ",") ?? 0}
                          </span>
                        </p>
                      </li>
                      <li>
                        <p>Total Raised:</p>
                        <div class="border-span" />
                        <b>
                          ${(
                            (parseInt(OverAllSaleToken) +
                              parseInt(bonusWithoutStage)) *
                            0.04
                          )
                            .toFixed(3)
                            ?.toString()
                            .replace(CommasRegex, ",") ?? 0}
                        </b>
                      </li>
                    </ul>
                    <div className="toekn-price-dis">
                      <h6>1 $RTX = $0.0193</h6>
                      <h5>Next Price: ${stage?.futuretokenprice}</h5>
                    </div>
                    <ul className="coin-card-list">
                      <li>
                        <Link>
                          <Image src={ETH} alt="ETH" /> ETH
                        </Link>
                      </li>
                      <li>
                        <Link >
                          <Image src={USDT} alt="USDT" /> USDT
                        </Link>
                      </li>
                      <li>
                        <Link >
                          <Image src={Card} alt="Card" /> Card
                        </Link>
                      </li>
                    </ul>
                    <Form>
                      <Row>
                        <Col xl="6" lg="6" md="6" sm="6">
                          <Form.Label>USDT You Pay</Form.Label>
                          <div className="border-input">
                            <Form.Group controlId="exampleForm.ControlInput1">
                              <Form.Control
                                style={{ color: "white" }}
                                type="text"
                                placeholder=""
                                onChange={(e) => setAmount(e.target.value)}
                              />
                            </Form.Group>
                            <Image
                              src={USDT}
                              alt="usdt"
                            />
                          </div>
                        </Col>
                        <Col xl="6" lg="6" md="6" sm="6">
                          <Form.Label>NDF Token You Receive</Form.Label>
                          <div className="border-input">
                            <Form.Group controlId="exampleForm.ControlInput1">
                              <Form.Control
                                style={{ color: "white" }}
                                type="text"
                                placeholder=""
                                value={calculateTokens(
                                  amount,
                                  selectedCurrency
                                )}
                              />
                            </Form.Group>
                            <Image src={logo} alt="Card" />
                          </div>
                        </Col>
                      </Row>
                    </Form>
                    <Link className="reg-btn br-radius w-100">
                      Connect wallet & Pay
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Row>
      </Container>
    </>
  );
}
export default Header;
