import React, { useContext, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Row, Container, Col, Tab, Tabs } from "react-bootstrap";
import Frequentlyquestion from "../Components/Frequentlyquestion";
import latestbg from "../Assets/img/Bg/bg-3.png";
import dawnbg from "../Assets/img/Bg/bg-dawn.png";
import arrowleft from "../Assets/img/Icons/icon-arrow-1.png";
import arrowright from "../Assets/img/Icons/icon-arrow-2.png";
import Partner1 from "../Assets/img/Partners/velas.png";
import Partner2 from "../Assets/img/Partners/duckdao.png";
import Partner3 from "../Assets/img/Partners/bondly.png";
import Partner4 from "../Assets/img/Partners/mxc.png";
import Partner5 from "../Assets/img/Partners/gbv.png";
import Partner6 from "../Assets/img/Partners/gate.png";
import Partner7 from "../Assets/img/Partners/au21.png";
import aboutbg from "../Assets/img/Bg/about-bg.png";
import Post from "../Assets/img/Posts/about.png";
import Roadmaplist from "../Components/Roadmaplist";
import UserContest from "../ContextAPI/UserContest";
import { Signup } from "../Api";
import { videoUrl } from "../Constants";
import { useAccount, useDisconnect } from "wagmi";
import LatestNewsSlider from "../Components/LatestNewsSlider";
function AboutVideo({
  setSignupShow,
  RewardSectionRef,
  TokenSectionRef,
  RoadSectionRef,
  FAQeSectionRef,
  SignupShow,
  featuredSectionRef,
  setShowNavBar,
  setShowFooter,
}) {
  const { CurrentStage, TotalSaleInStage } = useContext(UserContest);
  useEffect(() => {
    setShowNavBar(true);
    setShowFooter(true);
  }, []);
  const navigate = useNavigate();
  const { address, isConnecting, isDisconnected } = useAccount();
  // RewardSectionRef={RewardSectionRef}
  // TokenSectionRef={TokenSectionRef}
  // RoadSectionRef={RoadSectionRef}
  // WhiteSectionRef={WhiteSectionRef}

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.pathname]);

  return (
    <>
      <Container fluid className="header text-center">
        <div
          className="bg-layer"
          style={{ backgroundImage: `url(${aboutbg})` }}
        />
        <Row>
          <Container>
            <Row>
              <Col xl="12" lg="12">
                <div className="text-pnl video-container">
                  <h5>About new dawn finance</h5>
                  <h2>
                    Step Into the Future of
                    <br /> Crypto Trading
                  </h2>
                  {/* <Col xl="12">
                    <div className="video-pnl" style={{ height: "400px" }}>
                      <iframe
                        src={videoUrl}
                        height="400"
                        frameBorder="0" // Add this to remove iframe border if needed
                        allowFullScreen // Add this to enable fullscreen mode
                        title="video"
                      ></iframe>
                    </div>
                    <div className="spacer-50" />
                  </Col> */}
                  <p className="f-20">
                    New Dawn Finance is a platform that makes it easy to access
                    and participate in the exciting world of decentralized
                    finance. Our cross-chain platform enables a wide range of
                    people from all over the world to easily select or create
                    indexes in a simple to use platform.
                  </p>
                  <div className="spacer-20" />
                  <ul className="btn-list">
                    <li>
                      <Link className="reg-btn trans br-radius" href="#">
                        read White paper
                      </Link>
                    </li>
                    <li>
                      <Link className="reg-btn trans br-radius" href="#">
                        check one pager
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </Row>
      </Container>
    </>
  );
}
export default AboutVideo;
