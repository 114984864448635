import React from "react";
import { Link } from "react-router-dom";
import { Row, Container, Col } from "react-bootstrap";
function Faqs() {
    return (
        <>
            {/* Terms And Conditions Starts Here */}
            <section className="terms-policy container-fluid">
                <Row>
                    <Container>
                        <Row>
                            <Col xl="12" lg="12" md="12" className="text-center">
                                <h1>FAQs</h1>
                            </Col>
                            <Col xl="12" lg="12" md="12">
                                <h3>What is New Dawn Finance?</h3>
                                <p>
                                    New Dawn Finance is a cutting-edge decentralized algorithmic trading platform
                                    designed specifically for the fast-paced world of trading.
                                    With its advanced algorithms and machine learning capabilities,
                                    New Dawn Finance empowers traders to navigate the complex markets
                                    with efficiency, precision, and accuracy.</p>
                                <h3>What is the minimum purchase for NDFT tokens?</h3>
                                <p>
                                    The minimum purchase for NDFT tokens is $25

                                </p>
                                <h3>What cryptocurrencies do you accept as payment?</h3>
                                <p>
                                    We accept all major cryptocurrency payment e.g. Bitcoin, Ethereum, USDT, USDC,
                                    Solana, Dogecoin, Shiba Inu, BNB, Litecoin, Bitcoin Cash etc.
                                </p>
                                {/* <h3>Is New Dawn Finance audited?</h3>
                                <p>
                                    The New Dawn Finance smart contract has undergone a
                                    comprehensive audit conducted by the cybersecurity firm Snipe Finance, and our core
                                    team have also undergone an intense KYC Audit by Snipe Finance.
                                </p> */}
                                <h3>How Does  New Dawn Finance Token NDFT Work?</h3>
                                <p>
                                    The NDFT token is to utilise income and dividends to early investors spending hundreds of millions of pounds on R&D for futuristic strategies such as hedging, mean reversion etc.

                                    NDFT early investors will receive voting governance rights and will own parts of the software and will receive dividends from the profits the fund already makes.

                                </p>
                                <h3>What is the NDFT Supply?
                                </h3>
                                <p>
                                    The total supply is 350,000,000 NDFT.
                                </p>

                                <div className="spacer-40"/>
                            </Col>
                            <Col xl="12" lg="12" md="12" className="text-center">
                                <Link className="reg-btn br-radius trans" to="/">
                                    Back To Homepage
                                </Link>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </section>
            {/* Terms And Conditions Ends Here */}
        </>
    );
}
export default Faqs;
