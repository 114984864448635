import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Container, Col, Table, Form } from "react-bootstrap";
import NavBarDash from "../../Components/NavBarDash";
import Paginationnav from "../../Components/Paginationnav";
import iconbin from "../../Assets/img/admin-icons/icon-bin.png";
import carrot from "../../Assets/img/Icons/icon-carrot-arrow.png";
import arrowleft from "../../Assets/img/Icons/icon-arrow-1.png";
import arrowright from "../../Assets/img/Icons/icon-arrow-2.png";
import ChatButton from "../../Components/ChatButton";
import { deleteActivities, getUserActivity } from "../../Api";
import UserContest from "../../ContextAPI/UserContest";
import moment from "moment";
import { CircleLoader } from "react-spinners";
import { colorWhite } from "../../Constants";
import { Notifications } from "../../Components/Toast";
function AccountActivities({ setShowFooter, setShowNavBar }) {
  // Hide the footer when the Login component mounts
  useEffect(() => {
    setShowFooter(false);
    setShowNavBar(false);
  }, [setShowFooter, setShowNavBar]);
  const [userActicity, setUserActivity] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState();
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [Loader, setLoader] = useState(true);

  const {
    CurrentStage,
    TotalSaleInStage,
    OverAllSaleToken,
    SetPromocode,
    Promocode,
    SetUserDetail,
    UserDetail,
  } = useContext(UserContest);

  useEffect(() => {
    setLoader(true)
    getUserActivity(UserDetail?.id, 1).then((activity) => {
      setLoader(false)
      //  ("getUserActivity",activity)
      setUserActivity(activity.data)
      setTotalItems(activity.totalItems)
    }).catch(() => {
      setLoader(false)

    })

  }, [UserDetail])

  const OnPageChange = (page) => {
    setCurrentPage(page);
    setLoader(true);
    getUserActivity(UserDetail?.id, page)
      .then((activity) => {
        setLoader(false);
        //  ("getUserActivity",activity)
        setUserActivity(activity.data);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const DeleteActivity = () => {
    deleteActivities(UserDetail?.id)
      .then(() => {
        Notifications("success", "Activities Deleted Successfully ");
        setUserActivity([]);
      })
      .catch(() => {});
  };
  return (
    <>
      <NavBarDash />
      <ChatButton />
      <section className="admin-panel container-fluid">
        <Row>
          <Container>
            <Row>
              <Col xl="12" lg="12" md="12">
                <div className="text-center">
                  <h2>
                    <img src={arrowleft} alt="Arrow Left" /> Here is your recent
                    activities log <img src={arrowright} alt="Arrow Right" />
                  </h2>
                </div>
                <div className="spacer-20" />
                <div className="blue-post">
                  <Row>
                    <Col xl="12" lg="12" md="12">
                      <div className="flex-div-md align-items-center">
                        <p className="m-0 mt-3 font-weight-bold">
                          You can clear this log as well as disable the feature
                          from <Link href="#" className="simple-link purple"> profile settings tabs.</Link>
                        </p>
                        <div className="flex-md align-items-center">
                          <Link
                            onClick={() => {
                              DeleteActivity();
                            }}
                            className="reg-btn br-radius trans mt-3"
                          >
                            Clear Log
                          </Link>
                          {/* Talha */}
                          <Form>
                            {["checkbox"]?.map((type) => (
                              <div key={`default-${type}`} className="mx-3 mt-3 font-weight-bold">
                                <Form.Check 
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Confirm`}
                                />
                              </div>
                            ))}
                          </Form>
                        </div>
                      </div>
                      <div className="spacer-10" />
                    </Col>
                  </Row>
                  <div className="spacer-10" />
                  <div className="table-container">
                    <div className="table-container-inner">
                      <Table striped className="text-center h-b-less small">
                        <thead>
                          <tr>
                            <th>
                              <img src={carrot} alt="Arrow" />
                              Date
                            </th>
                            <th>
                              <img src={carrot} alt="Arrow" />
                              OS
                            </th>
                            <th>
                              <img src={carrot} alt="Arrow" />
                              Browser
                            </th>
                            <th>
                              <img src={carrot} alt="Arrow" />
                              IP Address
                            </th>
                            {/* <th><img src={carrot} alt="Arrow"/>{' '}Action</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {Loader ? (
                            <tr>
                              <td colSpan={8}>
                                <div className=" loader-circle">
                                  <CircleLoader color={colorWhite} size={42} />
                                </div>
                              </td>
                            </tr>
                          ) : (
                            <>
                              {userActicity?.map((item, index) => (
                                <tr key={index}>
                                  <td>
                                    {moment(item?.created_at).format(
                                      "DD MMM, YYYY hh:mm A"
                                    )}
                                  </td>
                                  {/* {("object", item)} */}
                                  <td>{item?.device}</td>
                                  <td>{item?.browser}</td>
                                  <td>{item?.ip_address}</td>
                                </tr>
                              ))}
                              {userActicity?.length === 0 && (
                                <tr>
                                  <td colSpan="4">No user activity found</td>
                                </tr>
                              )}
                            </>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="spacer-10" />
                  <div className="flex-div-sm align-items-center">
                    <p className="m-0">
                      <b className="notranslate">Showing 1 to 4 of 4 entries</b>
                    </p>
                    <Paginationnav
                      currentPage={currentPage}
                      totalItems={totalItems}
                      itemsPerPage={
                        parseInt(itemsPerPage) === 0 || !parseInt(itemsPerPage)
                          ? 1
                          : parseInt(itemsPerPage)
                      }
                      onPageChange={OnPageChange}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Row>
      </section>
    </>
  );
}
export default AccountActivities;
