import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Nav, Navbar, Container, NavDropdown, Dropdown } from "react-bootstrap";
import Logo from "../Assets/img/Logo/logo-svg.svg";
import icondashboard1 from "../Assets/img/admin-icons/icon-dashboard-2.png";
import icondashboard2 from "../Assets/img/admin-icons/icon-dashboard-1.png";
import iconcart1 from "../Assets/img/admin-icons/icon-cart-2.png";
import iconcart2 from "../Assets/img/admin-icons/icon-cart-1.png";
import icontransaction1 from "../Assets/img/admin-icons/icon-trasaction-2.png";
import icontransaction2 from "../Assets/img/admin-icons/icon-trasaction-1.png";
import iconprofile1 from "../Assets/img/admin-icons/icon-profile-2.png";
import iconprofile2 from "../Assets/img/admin-icons/icon-profile-1.png";
import iconToken1 from "../Assets/img/admin-icons/icon-token-2.png";
import iconToken2 from "../Assets/img/admin-icons/icon-token-1.png";
import iconweb1 from "../Assets/img/admin-icons/icon-website-2.png";
import iconweb2 from "../Assets/img/admin-icons/icon-website-1.png";
import iconuser1 from "../Assets/img/admin-icons/icon-user.png";
import iconchain1 from "../Assets/img/admin-icons/icon-chain.png";
import iconclock1 from "../Assets/img/admin-icons/icon-clock.png";
import iconlogout1 from "../Assets/img/admin-icons/icon-logout.png";
import flag1 from "../Assets/img/flags/icon-australia-flag.png";
import flag2 from "../Assets/img/flags/icon-uae.png";
import flag3 from "../Assets/img/flags/icon-united-states-flag.png";
import UserContest from "../ContextAPI/UserContest";
import { getUserDetail } from "../Api";
import DisplayUserName from "./Displayname";
import { Google_Script_Url, Languages } from "../Constants";
import { FaWindowRestore } from "react-icons/fa";

function NavBarDash() {
  const navigate = useNavigate();
  const {
    CurrentStage,
    TotalSaleInStage,
    Promotioncode,
    OverAllSaleToken,
    SetPromocode,
    Promocode,
    SetUserDetail,
    UserDetail,
  } = useContext(UserContest);
  const [language, setlanguage] = useState();

  const [selectedLanguage, setSelectedLanguage] = useState("en");
  useEffect(() => {
    getUserDetail()
      .then((detail) => {
        SetUserDetail(detail);
      })
      .catch(() => {
        {
          if (localStorage.getItem("JwtToken")) {
            localStorage.clear();
          }
        }

        navigate("/");
      });
  }, []);

  useEffect(() => {
    try {
      const cookie = document?.cookie
        ?.split(";")
        ?.find((cookie) => cookie?.includes("googtrans"));

      if (cookie) {
        const languageFromCookie = cookie.split("/")[2];

        if (languageFromCookie) {
          setSelectedLanguage(languageFromCookie);
        }
      }
    } catch (error) {
      // console.error("Error while processing cookie:", error);
    }
  }, []);

  function removeCookie2(name) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }
  const removeCookie3 = (name, domain) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain};`;
  };

  function setCookie2(key, value, expiry) {
    // ("mazak ha key", key)
    removeCookie2(key);
    return new Promise((resolve) => {
      setTimeout(() => {
        var existingCookie = document.cookie;
        var cookies = existingCookie?.split(";")[0];
        // ("mazak ha existing existingCookie?split[0]", existingCookie?.split(";")[0])
        // ("mazak ha existing Cookie", existingCookie)
        // ("mazak ha existing split ", cookies)

        // Check if the key already exists in the cookies
        var updatedCookie = false;
        for (var i = 0; i < cookies.length; i++) {
          var cookie = cookies[i].trim();
          if (cookie.indexOf(key + "=") === 0) {
            updatedCookie = true;
            // Update the existing cookie value
            var cookieValue = cookie.substring(key.length + 1);
            document.cookie =
              key + "=" + value + "; expires=" + expiry + "; path=/";
            break;
          }
        }

        // ("mazak ha updated updatedCookie", updatedCookie)

        if (!updatedCookie) {
          // Create a new cookie if the key doesn't exist
          document.cookie =
            key + "=" + value + "; expires=" + expiry + "; path=/";
        }
        resolve();
      }, 1000);
    });
  }
  function setCookie3(key, value, expiry, domain) {
    // (key, value, expiry,domain )
    removeCookie3(key, domain);
    return new Promise((resolve) => {
      setTimeout(() => {
        var existingCookie = document.cookie;
        var cookies = existingCookie.split(";");

        var updatedCookie = false;

        for (var i = 0; i < cookies.length; i++) {
          var cookie = cookies[i].trim();
          if (cookie.indexOf(key + "=") === 0) {
            updatedCookie = true;
            var cookieValue = cookie.substring(key.length + 1);
            document.cookie =
              key +
              "=" +
              value +
              "; expires=" +
              expiry +
              "; path=/; domain=" +
              domain;
            break;
          }
        }

        if (!updatedCookie) {
          document.cookie =
            key +
            "=" +
            value +
            "; expires=" +
            expiry +
            "; path=/; domain=" +
            domain;
        }

        resolve();
      }, 1000);
    });
  }

  const googleTranslateElementInit = () => {
    const element = new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        includedLanguages: "en,es,ar,zh-CN,fr,de,id,it,ko,pt,es,th,vi",
        layout:
          window.google.translate.TranslateElement.InlineLayout.HORIZONTAL,
        autoDisplay: true,
      },
      "google_translate_element"
    );
  };
  useEffect(() => {
    // (document.documentElement.lang,"language")

    const interval = setInterval(() => {
      if (
        document.documentElement.lang === localStorage.getItem("Language") ||
        (document.documentElement.lang === "en" &&
          localStorage.getItem("Language") === "lo")
      ) {
        clearInterval(interval);
      }
    }, [1000]);
  }, []);

  useEffect(() => {
    // (" googleTranslateElementInit true")
    var addScript = document.createElement("script");
    addScript.setAttribute("src", Google_Script_Url);
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;

    // (" googleTranslateElementInit false")
  }, [language]);

  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary dashboard-navbar">
        <Container fluid>
          <Navbar.Brand href="/Dashboard">
            <img src={Logo} alt="Logo" />
          </Navbar.Brand>
          {/* <div id="google_translate_element" /> */}

          <div className="mobile-view-flex">
            <Dropdown className="navbar-dropdown notranslate">
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {selectedLanguage ? (
                  Languages?.map((item) => {
                    if (selectedLanguage && item.symbol === selectedLanguage) {
                      return (
                        <React.Fragment key={item.symbol}>
                          {item && <img src={item.image} />}
                        </React.Fragment>
                      );
                    }
                    return null; // Returning null when the condition is not met
                  })
                ) : (
                  <img
                    src={Languages.find((item) => item?.symbol === "en").image}
                  />
                )}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {Languages?.map((item, index) => (
                  <Dropdown.Item
                    href={void 0}
                    key={item.symbol}
                    onClick={async () => {
                      localStorage.setItem("Language", item?.symbol);
                      setlanguage(item?.symbol);
                      await setCookie2("googtrans", `/en/${item.symbol}`, 1);
                      await setCookie3(
                        "googtrans",
                        `/en/${item.symbol}`,
                        1,
                        ".NewDawnFinance.trade"
                      );
                      // window.location.reload()
                    }}
                  >
                    <img src={item.image} alt="flag" />{" "}
                    {item?.symbol?.toUpperCase()}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <NavDropdown
              id="basic-nav-dropdown"
              title={<DisplayUserName userDetail={UserDetail} />}
            >
              <NavDropdown.Item href={void 0}>
                <b className="notranslate">
                  Hello,{" "}
                  {UserDetail?.wallet_address
                    ? UserDetail.wallet_address.slice(0, 5) +
                      "..." +
                      UserDetail.wallet_address.slice(-5)
                    : "Guest"}
                  !
                </b>{" "}
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => {
                  navigate("/MyProfileDetails");
                }}
                href={void 0}
              >
                <div className="img">
                  <img src={iconuser1} alt="Icon User" />
                </div>{" "}
                My Profile
              </NavDropdown.Item>

              <NavDropdown.Item
                onClick={() => {
                  navigate("/Referral");
                }}
                href={void 0}
              >
                <div className="img">
                  <img src={iconchain1} alt="Icon Chain" />
                </div>{" "}
                My Referral
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => {
                  navigate("/AccountActivities");
                }}
                href={void 0}
              >
                <div className="img">
                  <img src={iconclock1} alt="Icon Clock" />
                </div>{" "}
                Login History
              </NavDropdown.Item>
              <NavDropdown.Item
                href="/"
                onClick={() => {
                  localStorage.clear();
                  // navigate('/')
                }}
              >
                <div className="img">
                  <img src={iconlogout1} alt="Icon Logount" />
                </div>{" "}
                Logout
              </NavDropdown.Item>
            </NavDropdown>
            <Navbar.Toggle aria-controls="navbarScroll" />
          </div>
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto my-2 my-lg-0">
              <Nav.Link
                onClick={() => navigate("/Dashboard")}
                href={void 0}
                className={`${
                  useLocation().pathname == "/Dashboard" ? "active" : ""
                }`}
              >
                <div className="img">
                  <img src={icondashboard1} alt="Dashboard" />
                  <img src={icondashboard2} alt="Dashboard" />
                </div>{" "}
                Dashboard
              </Nav.Link>
              <Nav.Link
                onClick={() => {
                  navigate("/PurchaseToken");
                }}
                href={void 0}
                className={`${
                  useLocation().pathname == "/PurchaseToken" ? "active" : ""
                }`}
              >
                <div className="img">
                  <img src={iconcart1} alt="Icon cart" />
                  <img src={iconcart2} alt="Icon cart" />
                </div>{" "}
                Purchase Token
              </Nav.Link>
              <Nav.Link
                onClick={() => {
                  navigate("/Transactions");
                }}
                href={void 0}
                className={`${
                  useLocation().pathname == "/Transactions" ? "active" : ""
                }`}
              >
                <div className="img">
                  <img src={icontransaction1} alt="Icon transaction" />
                  <img src={icontransaction2} alt="Icon transaction" />
                </div>{" "}
                Transactions
              </Nav.Link>
              <Nav.Link
                onClick={() => {
                  navigate("/MyProfileDetails");
                }}
                href={void 0}
                className={`${
                  useLocation().pathname == "/MyProfileDetails" ? "active" : ""
                }`}
              >
                <div className="img">
                  <img src={iconprofile1} alt="Icon Profile" />
                  <img src={iconprofile2} alt="Icon Profile" />
                </div>{" "}
                Profile
              </Nav.Link>
              <Nav.Link
                onClick={() => {
                  navigate("/MyToken");
                }}
                href={void 0}
                className={`${
                  useLocation().pathname == "/MyToken" ? "active" : ""
                }`}
              >
                <div className="img">
                  <img src={iconToken1} alt="Icon Token" />
                  <img src={iconToken2} alt="Icon Token" />
                </div>{" "}
                My Token
              </Nav.Link>

              <Nav.Link
                onClick={() => {
                  navigate("/"); 
                }}
              >
                <div className="img">
                  <img src={iconweb1} alt="Icon web" />
                  <img src={iconweb2} alt="Icon web" />
                </div>
                Main Website
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
export default NavBarDash;
