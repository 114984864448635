import React, { useContext, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Row, Container, Col, Image } from "react-bootstrap";
import Joinpresalenow from "../Components/Joinpresalenow";
import globebg from "../Assets/img/Bg/bg-2.png";
import anglebg from "../Assets/img/Posts/bg-angle.png";
import dawnbg from "../Assets/img/Bg/bg-dawn.png";
import partnershipbg from "../Assets/img/Bg/bg-dawn.png";
import arrowleft from "../Assets/img/Icons/icon-arrow-1.png";
import arrowright from "../Assets/img/Icons/icon-arrow-2.png";
import angle from "../Assets/img/Posts/angle.png";
import buy from "../Assets/img/Posts/buy.png";
import dashboard from "../Assets/img/Posts/dashboard.png";
import mobile from "../Assets/img/Posts/mobile.png";
import wallet from "../Assets/img/Posts/connectwalley.png";
import launching1 from "../Assets/img/Bg/ndf-launching.jpg";
import launching2 from "../Assets/img/Bg/ndf-rocket.png";
import launching3 from "../Assets/img/Bg/ndf-launching.png";
import planet1 from "../Assets/img/Bg/planet-backo.png";
import planet2 from "../Assets/img/Bg/planet.jpg";
import bluebg from "../Assets/img/Bg/bg-6.png";
import astronot from "../Assets/img/Bg/astro.png";
import cryptoindex from "../Assets/img/Posts/cryptoindex.png";
import choosyield from "../Assets/img/Posts/choos-yield.png";
import artificialbg from "../Assets/img/Bg/moon-bg.png";
import arrowrighty from "../Assets/img/Icons/arrow.png";
import iconbasket from "../Assets/img/Icons/icon-basket.png";
import iconyield from "../Assets/img/Icons/icon-yield.png";
import icongrowth from "../Assets/img/Icons/icon-growth.png";
import Header from "../Components/Header";
import { PresaleButton, PresalesButton } from "../Components/Buttons";
import UserContest from "../ContextAPI/UserContest";
import { Signup } from "../Api";
import { useAccount, useDisconnect } from "wagmi";
import Aboutlist from "../Components/Aboutlist";
import BuyToken from "../Components/BuyToken";
import Frequentlyquestion from "../Components/Frequentlyquestion";
import LatestNewsSlider from "../Components/LatestNewsSlider";
import Tokenomics from "../Components/Tokenomics";
import IncomeList from "../Components/IncomeList";
function Home({
  setSignupShow,
  RewardSectionRef,
  TokenSectionRef,
  RoadSectionRef,
  FAQeSectionRef,
  SignupShow,
  PlateformSectionRef,
  IndexesSectionRef,
  setShowNavBar,
  setShowFooter,
}) {
  const { CurrentStage, TotalSaleInStage } = useContext(UserContest);
  useEffect(() => {
    setShowNavBar(true);
    setShowFooter(true);
  }, []);
  const navigate = useNavigate();
  const { address, isConnecting, isDisconnected } = useAccount();
  // RewardSectionRef={RewardSectionRef}
  // TokenSectionRef={TokenSectionRef}
  // RoadSectionRef={RoadSectionRef}
  // WhiteSectionRef={WhiteSectionRef}

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.pathname]);

  return (
    <>
      {/* Header Starts Here */}
      {/* <Header setSignupShow={setSignupShow} SignupShow={SignupShow} /> */}
      {/* Header Starts Here */}

      {/* Buy Token */}
      <BuyToken setSignupShow={setSignupShow} SignupShow={SignupShow} />
      {/* Buy Token */}

      {/* Featured Panel Starts Here */}
      {/* <section className="container-fluid latest-news-panel text-center">
        <div
          className="bg-layer"
          style={{ backgroundImage: `url(${dawnbg})`, opacity: '1' }}
        />
        <Row>
          <Container>
            <Row>
              <Col
                xxl={{ span: 6, offset: 3 }}
                xl={{ span: 8, offset: 2 }}
                lg={{ span: 10, offset: 1 }}
              >
                <h2 className="z-ind">
                  <img src={arrowleft} alt="Arrow Left" /> Latest News{" "}
                  <img src={arrowright} alt="Arrow Right" />
                </h2>
                <h4 className="lighter-yellow font-weight-normal Titillium z-ind">
                  News, Blog and Educational in finance world
                </h4>
                <div className="spacer-20" />
              </Col>
            </Row>
            <Row>
              <Col xl="12" lg="12" md="12">
                <LatestNewsSlider />
              </Col>
            </Row>
          </Container>
        </Row>
      </section> */}
      {/* Featured Panel Ends Here */}
      {/* Onboard Seamlessly */}
      <section className="text-container container-fluid artificial-pnl">
        <div
          className="bg-layer"
          style={{ backgroundImage: `url(${artificialbg})` }}
        />
        <Row
          onClick={() => {
            setSignupShow(true);
          }}
        >
          <Container>
            <Row>
              <Col xl="5" lg="5" md="12">
                <h5 className="gradient-color text-uppercase font-weight-bold">
                  What is NDF
                </h5>
                <h2 className="chakra">
                  Where Artificial Intelligence Meets Decentralized
                  Growth - New Dawn Finance
                </h2>
                <div className="spacer-10" />
                <Link className="reg-btn br-radius trans" to="/HowToBuy">
                  Read Whitepaper
                </Link>
                <div className="spacer-30" />
              </Col>
              <Col xl={{ span: 6, offset: 1 }} lg={{ span: 7, offset: 0 }} md={{ span: 12, offset: 0 }}>
                <p>
                  New Dawn Finance represents a groundbreaking shift in
                  asset management. Our decentralized hedge fund is designed
                  as a first-of-its-kind initiative backed entirely by
                  artificial intelligence. Unlike traditional hedge funds
                  that rely on human traders and complex layers, New Dawn
                  Finance relies on decentralized decision-making to
                  identify gaps in the market and automatically deploy
                  capital. Our decision-making framework relies on
                  transparency and efficiency to deliver wealth-building
                  opportunities.
                </p>
                <p>
                  Our platform draws from decades of market data to curate
                  trading strategies that are the culmination of expertise
                  from industry-leading hedge funds and traders to create a
                  high-performance trading model. With its basis in
                  historical data and risk management, our AI is designed to
                  mitigate market risk and maximize stable returns.
                </p>
              </Col>
              <Col lg={{ span: 10, offset: 1 }} md={{ span: 10, offset: 1 }}>
                <Image src={astronot} alt="astronot"/>
              </Col>
            </Row>
          </Container>
        </Row>
      </section>
      {/* Onboard Seamlessly */}


      {/* Unless Wealht Income */}
      <section className="container-fluid incom-pnl">
        <div
          className="bg-layer"
          style={{ backgroundImage: `url(${bluebg})` }}
        />
        <Row>
          <Container>
            <Row
              onClick={() => {
                setSignupShow(true);
              }}
            >
              <Col xl="12" lg="12" md="12" sm="12" className="text-center">
                <h5 className="textgradient font-weight-bold Titillium z-ind">
                  How-It-Works
                </h5>
                <h2>
                  <img src={arrowleft} alt="Arrow Left" /> Unless Wealth With
                  Passive Income <img src={arrowright} alt="Arrow Right" />
                </h2>
                <h4 className="textgradient font-weight-normal Titillium z-ind">
                  3 Simple Steps
                </h4>
                <div className="spacer-50" />
              </Col>
              <Col xl="12" lg="12" md="12" sm="12">
                <IncomeList />
                <div className="spacer-20" />
              </Col>
              <Col xl="12" lg="12" md="12" sm="12" className="text-center">
                <ul className="btn-list justify-center mv-full">
                  <li>
                    <Link
                      onClick={() => {
                        setSignupShow(true);
                      }}
                      className="reg-btn br-radius"
                      to={void 0}
                    >
                      JOIN Presale
                    </Link>
                  </li>
                  <li>
                    <Link className="reg-btn br-radius trans" to="/HowToBuy">
                      Learn More
                    </Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </Row>
      </section>
      {/* Unless Wealht Income */}



      {/* PlateForm featured */}
      <div ref={PlateformSectionRef}>
        {/* <div className="bg-container">
          <div
            className="bgg-layer"
            style={{ backgroundImage: `url(${globebg})` }}
          />

          <section className="text-container index-creator-panel container-fluid">
            <Row
              onClick={() => {
                setSignupShow(true);
              }}
            >
              <Container>
                <Row>
                  <Col xl="12" lg="12" md="12" className="text-center">
                    <h2>
                      {" "}
                      <img src={arrowleft} alt="Arrow Left" /> Platform Features{" "}
                      <img src={arrowright} alt="Arrow Right" />
                    </h2>
                    <h4 className="gradient-color max-width-600">
                      Whether you are ready to select from a menu of indexes or
                      if you’re looking to create new enticing ones, New Dawn
                      Finance offers the tools for managing a wide range of
                      strategies in the DeFi industry.
                    </h4>
                    <div className="spacer-30" />
                  </Col>
                  <Col xl="5" lg="5" md="5" className="text-center">
                    <div className="txt align-items-center">
                      <h3 className="gradient-color chakra">
                        For Index Selectors
                      </h3>
                      <p>
                        The New Dawn Finance platform functions like a First
                        Class meal where you get to pick from a selection of
                        only the top DeFi strategies.
                      </p>
                      <div className="spacer-20" />
                    </div>
                  </Col>
                  <Col xl="7" lg="7" md="7" className="text-center">
                    <div className="white-shadow img-pnl">
                      <img src={cryptoindex} alt="post" />
                      <img src={cryptoindex} alt="post" />
                    </div>
                  </Col>
                  <Col xl="12" lg="12" md="12">
                    <div className="spacer-40" />
                  </Col>
                </Row>
                <Row>
                  <Col
                    xl={{ span: 5, order: 2 }}
                    lg={{ span: 5, order: 2 }}
                    md={{ span: 5, order: 2 }}
                    className="text-center"
                  >
                    <div className="txt align-items-center">
                      <h3 className="gradient-color chakra">
                        For Index Creators
                      </h3>
                      <p>
                        Create your own carefully curated menu of DeFi
                        strategies and serve them up to the New Dawn Finance
                        community with precise fine tuning.
                      </p>
                      <div className="spacer-20" />
                    </div>
                  </Col>
                  <Col xl="7" lg="7" md="7" className="text-center">
                    <div className="white-shadow img-pnl">
                      <img src={choosyield} alt="post" />
                      <img src={cryptoindex} alt="post" />
                    </div>
                  </Col>
                </Row>
              </Container>
            </Row>
          </section>
        </div> */}

        {/* PlateForm featured */}


        {/* Join Pre Sale Now Panel */}
        <Joinpresalenow setSignupShow={setSignupShow} />
        {/* Join Pre Sale Now Panel */}

        {/* About List */}
        <section className="container-fluid about-pnl">
          <Row>
            <Container>
              <Row
                onClick={() => {
                  setSignupShow(true);
                }}
              >
                <Col xl="12" lg="12" md="12" sm="12">
                  <Aboutlist />
                  <div className="spacer-20" />
                </Col>
              </Row>
            </Container>
          </Row>
        </section>
        {/* About List */}

        {/* Unlimited Index */}
        {/* <section ref={IndexesSectionRef} className="container-fluid index-pnl">
          <div
            className="bg-layer"
            style={{ backgroundImage: `url(${partnershipbg})` }}
          />
          <Row
            onClick={() => {
              setSignupShow(true);
            }}
          >
            <Container>
              <Row>
                <Col xl="5" lg="5" md="4" sm="12">
                  <div className="mobile-text-center">
                    <h2 className="mobile-break z-ind">
                      <img src={arrowleft} alt="Arrow Left" /> <br /> Unlimited
                      <br /> Index
                      <br /> Types
                      <br /> <img src={arrowright} alt="Arrow Right" />
                    </h2>
                    <div className="spacer-20" />
                    <h4 className="">
                      Imagine the possibilities of what you can
                      <br /> select or create with New Dawn Finance!
                    </h4>
                  </div>
                  <div className="spacer-20" />
                </Col>
                <Col xl="7" lg="7" md="8" sm="12">
                  <ul className="index-list">
                    <li>
                      <img src={iconbasket} alt="Icon Basket" />
                      <div className="txt-pnl">
                        <h4 className="chakra">
                          Basket <img src={arrowrighty} alt="Arrow Right" />
                        </h4>
                        <p>
                          An enticing basket of the top 5 cryptos including the
                          likes of Bitcoin, Ethereum, Uniswap, etc.
                        </p>
                      </div>
                    </li>
                    <li>
                      <img src={iconyield} alt="Icon Basket" />
                      <div className="txt-pnl">
                        <h4 className="chakra">
                          yield <img src={arrowrighty} alt="Arrow Right" />
                        </h4>
                        <p>
                          A dynamic index that allocates underlying assets to
                          the highest APY yield farming pools.
                        </p>
                      </div>
                    </li>
                    <li>
                      <img src={icongrowth} alt="Icon Basket" />
                      <div className="txt-pnl">
                        <h4 className="chakra">
                          Growth <img src={arrowrighty} alt="Arrow Right" />
                        </h4>
                        <p>
                          An astounding index that automatically adjusts to the
                          3 fastest growing cryptos.
                        </p>
                      </div>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Container>
          </Row>
        </section> */}
        {/* Unlimited Index */}

        {/* Get Started Now */}
        {/* <section className="text-container container-fluid">
          <Row
            onClick={() => {
              setSignupShow(true);
            }}
          >
            <Container>
              <Row>
                <Col xl="12" lg="12" md="12">
                  <div className="anime-text-pnl get-panel">
                    <div className="anime-img-panel">
                      <span />
                      <span />
                      <span />
                      <img className="angle-img" src={angle} alt="Angle" />
                      <div className="img-pnl">
                        <div className="img-pnl-inner">
                          <img src={dashboard} alt="Dashboard" />
                          <img src={mobile} alt="mobile" />
                        </div>
                      </div>
                    </div>
                    <div className="txt mobile-text-center">
                      <h2>
                        <img src={arrowleft} alt="Arrow Left" /> Get started
                        now! <img src={arrowright} alt="Arrow Right" />
                      </h2>
                      <div className="spacer-20" />
                      <h4 className="whitecolor">
                        You’ll find that the{" "}
                        <span className="yellow-color">New Dawn Finance</span>{" "}
                        platform is easy to use and intuitive so you can get{" "}
                        <span className="yellow-color">New Dawn Financing</span>{" "}
                        right away. Welcome to a new world of DeFi.
                      </h4>
                      <div className="spacer-30" />
                      <Link className="reg-btn br-radius" href="#">
                        GET STARTED
                      </Link>
                      <div className="spacer-20" />
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Row>
        </section> */}
        {/* Get Started Now */}

        {/* Tokenomics Starts Here */}
        <section onClick={() => { setSignupShow(true); }} ref={TokenSectionRef} className="container-fluid tokenomics-pnl text-center">
          <div className="bg-layer" style={{ backgroundImage: ` url(${planet1}), url(${planet2})`, }} />
          <Row>
            <Container>
              <Row>
                <Col xxl={{ span: 6, offset: 3 }} xl={{ span: 8, offset: 2 }}>
                  <Tokenomics />
                </Col>
              </Row>
            </Container>
          </Row>
        </section>
        {/* Tokenomics Ends Here */}

        {/* NDF PRofit Launch */}
        <section className="container-fluid">
          <Row>
            <div className="profit-launch-post">
              <div className="profit-launch-post-inner">
                <div className="bg-layer" style={{ backgroundImage: ` url(${launching2}), url(${launching1})`, }} />
                <div className="bg-layer-1" style={{ backgroundImage: `url(${launching3})` }} />
                <Container>
                  <Row>
                    <Col xl="12" lg="12" md="12">
                      <div className="d-flex">
                        <h2 className="bg-text">NDF </h2>{" "}
                        <h2 className="gradient-color">Profit</h2>
                      </div>
                      <h2 className="gradient-color">Since Launch</h2>
                      <h2><span>65.2% </span></h2>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </Row>
        </section>
        {/* NDF PRofit Launch */}

        {/* Freuently Asked Question */}
        <section className="container-fluid bg-shadow">
          <Row>
            <Container>
              <Row>
                <Col xl="12" lg="12" md="12" className="text-center">
                  <h2>Frequently Asked Questions!</h2>
                </Col>
                <Col xl={{ span: 8, offset: 2 }} lg={{ span: 10, offset: 1 }} md={{ span: 12, offset: 0 }} sm={{ span: 12, offset: 0 }} xs={{ span: 12, offset: 0 }}>
                  <Frequentlyquestion />
                </Col>
              </Row>
            </Container>
          </Row>
        </section>
        {/* Freuently Asked Question */}
      </div>
    </>
  );
}
export default Home;
